<template>
  <div>
    <ViewProfile
      v-if="$route.name === 'profile' && renderComponent"
      :poc="poc"
      :account-info="accountInfo"
      @reload-profile="getUsersData"
      @clicked="toggleViews"
    />

    <EditProfile
      v-if="$route.name === 'edit-profile' && renderComponent"
      :account-info="accountInfo"
      @clicked="toggleViews"
      @updateProfile="updateProf"
    />
  </div>
</template>

<script>
// import { allIndustries } from '@/constants/datastore'
import ViewProfile from './ViewProfile.vue'
import EditProfile from './EditProfile.vue'

export default {
  components: {
    ViewProfile,
    EditProfile,

  },
  data: () => ({
		renderComponent: false,
    isLoading: true,
    profile_type: true,
    view_data: true,
    accountInfo: {
      account_type: '',
      user_group: [],
      resume_file: '',
      organization: '',
      fullname: '',
      email_address: '',
      gender: '',
      phone_number: '',
      employment_status: '',
      job_type: {},
      date_of_birth: '',
      highest_qualification: '',

      citizenship: '',
      preferred_location: [],
      preferred_position: [],
      preferred_industry: [],
      availability: { number: '', period: '' },
      postal: '',
      days_left: '',
      two_factor: false,
      verified: false,
      telegram_id: 0,
    },
    companyInfo: {
      company_name: '',
      uen_no: '',
      company_image: '',
      official_name: '',
      company_summary: '',
    },
    employmentData: [],
    educationData: [],

    poc: {
      fullname: '',
      email_address: '',
      phone_number: '',
      parttime: false,
      fulltime: false,
      placement: false,
      outsourcing: false,
      payroll: false,
      commission: '',
      offer_comp: false,
      other_func: false,
      agency_number: '',
      recruiter_number: '',
      bulk_commission: '',
      industries: '',
      extra_functions: '',
      num_orders: '',
      preferred_industries: [],
      selected_industries: [],
      qualifications: [
        {
          text: 'PSLE',
          value: 'PSLE',
        },
        {
          text: 'N-levels',
          value: 'N-levels',
        },
        {
          text: 'O-levels',
          value: 'O-levels',
        },
        {
          text: 'Nitec',
          value: 'Nitec',
        },
        {
          text: 'Higher Nitec',
          value: 'Higher Nitec',
        },
        {
          text: 'Diploma',
          value: 'Diploma',
        },
        {
          text: 'A-levels',
          value: 'A-levels',
        },
        {
          text: 'Advanced Diploma',
          value: 'Advanced Diploma',
        },
        {
          text: 'Degree',
          value: 'Degree',
        },

        {
          text: 'Masters',
          value: 'Masters',
        },

        {
          text: 'PhD',
          value: 'PhD',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],
      allIndustries: [],
    },
  }),
  watch: {
    '$route.name': function (newVal) {
      if (this.$route.name === 'edit-profile') {
        this.view_data = false
      }
      if (this.$route.name === 'profile') {
        this.view_data = true
      }
    },
  },
  mounted() {
    // this.allIndustries = allIndustries
    this.getUsersData()
			.then(response => {
				this.$set(this, 'renderComponent', true)
			})
  },
  methods: {
    reloadProfile() {
      this.getUsersData()
      this.getCompanyInfo()
    },
    updateProf() {
      this.getUsersData()
      this.view_data = false
    },
    toggleViews(value) {
      this.view_data = value
      this.getUsersData()
    },
    getUsersData() {
			return new Promise((resolve, reject) => {
				// this.isLoading = true
				this.isLoading = true
				this.$http
					.get('/api/user')
					.then(response => {
						const { output } = response.data
						this.accountInfo.id = output.id
						this.accountInfo.avatar = output.profile_image ? output.profile_image + `?${Math.random(100, 999)}` : this.$defaultProfileImage
						this.accountInfo.first_name = output.first_name
						this.accountInfo.last_name = output.last_name
						this.accountInfo.username = output.username
						this.accountInfo.email = output.email
						this.accountInfo.mobile_number = output.mobile_number
	
						// this.$store.commit('auth/UPDATE_PROFILE', response.data.profile_image)
						// if (response.data.errors) {
						//   if (response.data.errors[0] === 'User not found') {
						//     localStorage.removeItem('userInfo')
						//     localStorage.removeItem('email')
						//     localStorage.removeItem('accessToken')
						//     this.$router.push('/auth/login')
						//   }
						// }
						// this.isLoading = false
	
						// const returned_data = response.data
						// const profile_state = returned_data.profile_status
						// if (!profile_state.incomplete) {
						//   // if (profile_state.company == 'e2i') {
						//   // }
	
						//   // this.show_e2i = true;
						//   // this.custom_popup = true;
						//   // this.custom_questions = profile_state.questions;
						// }
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})

    },
  },
}
</script>
