<template>
  <div id="page-user-view-edit">
    <div id="user-data">
      <b-card
        v-if="Object.keys(accountInfo).length > 0"
        class="mb-base"
      >
        <div
          class="vx-row my-1 ml-1"
        >
          <h6>My Profile</h6>
        </div>

        <!-- Avatar -->
        <div class="d-flex flex-row flex-wrap justify-content-start">
          <!-- Avatar Col -->
          <b-col
            cols="12"
            md="4"
            lg="2"
          >
            <div
              class="d-flex justify-content-center mb-2 w-100"
              style="width: 200px; height: 200px"
            >
              <b-img
                :src="
                  accountInfo.avatar === 'default' || accountInfo.avatar == null
                    ? require('@/assets/images/placeholders/no-image.jpg')
                    : accountInfo.avatar
                "
                class="float-left"
                style="width: 200px; height: 200px"
              />

						</div>

            <div
              class="w-100 mt-2"
            >
              <b-button
                id="edit-btn"
                icon-pack="feather"
                icon="icon-edit"
                class="w-100 mt-2"
                variant="primary"
                @click="gotoEdit"
              >Edit</b-button>
            </div>
            <div
              v-if="false"
              class="w-100 mt-2"
            >
              <b-button
                icon-pack="feather"
                icon="icon-edit"
                class="w-100"
                variant="warning"
                @click="resendVerification"
              >Verify Account</b-button>
            </div>
          </b-col>

          <!-- Information - Col 1 -->
          <b-col
            cols="12"
            md="4"
            lg="5"
          >
            <table
              class="w-100"
              :class="profileType ? 'h-auto' : 'h-12 mt-4'"
            >
              <tr>
                <td class="font-semibold p-1 w-50">
                  First Name
                </td>
                <td class="p-1 w-50">
                  {{ getDefaultValue(accountInfo.first_name) }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold p-1 w-50">
                  Last Name
                </td>
                <td class="p-1 w-50">
                  {{ getDefaultValue(accountInfo.last_name) }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold p-1 w-50">
                  Username
                </td>
                <td class="p-1 w-50">
                  {{ getDefaultValue(accountInfo.username) }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold p-1 w-50">
                  Email
                </td>
                <td class="p-1 w-50">
                  {{ getDefaultValue(accountInfo.email) }}
                </td>
              </tr>

              <tr>
                <td class="font-semibold p-1 w-50">
                  Mobile Number
                </td>
                <td class="p-1 w-50">
                  {{ getDefaultValue(accountInfo.mobile_number) }}
                </td>
              </tr>

            </table>
          </b-col>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <b-col
            cols="12"
            md="4"
            lg="5"
          >
            <table class="divide-x divide-light-blue-400 w-100">
            </table>
          </b-col>
        </div>
      </b-card>

      <v-tour
        v-if="!finished_tour"
        name="ProfileTour"
        :steps="steps"
        :callbacks="tourCallbacks"
        :options="{ highlight: false }"
      />
      <!-- Permissions -->
    </div>
  </div>
</template>

<script>
import { format, formatDistance } from 'date-fns'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ContactVerification from '@/components/VerifyContact.vue'

import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBTooltip,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BAlert,
  BImg,
} from 'bootstrap-vue'

import EditProfile from './EditProfile.vue'

export default {
  components: {
    // ContactVerification,
    EditProfile,
    BCard,
    BImg,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBTooltip,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [],
  props: {
    accountInfo: { type: Object, required: true },
    companyInfo: { type: Object, default: function () { return {} } },
    employmentInfo: { type: Array, default: function () { return [] } },
    educationInfo: { type: Array, default: function () { return [] } },
    profileType: { type: Boolean, default: true },
  },
  data() {
    return {
      openVerifyPopup: false,
      verifyType: 'phoneNumber',
      tourCallbacks: {
        onSkip: this.finishTour,
        onFinish: this.finishTour,
        onPreviousStep: this.changeOfTab,
        onNextStep: this.changeOfTab,
      },
      finished_tour: false,
      steps: [
        {
          target: '#edit-btn', // We're using document.querySelector() under the hood
          content: 'Click here to edit your account details.',
        },
        {
          target: '#edit-btn', // We're using document.querySelector() under the hood
          content: 'Click here to edit your account details.',
        },
      ],
      isLoading: true,

      showEmployments: true,
      showEducations: true,
    }
  },

  watch: {
    isTablet(val) {
      if (val) {
        this.showEmployments = true
        this.showEducations = false
      } else {
        this.showEmployments = true
        this.showEducations = true
      }
    },
    isMobile(val) {
      if (val) {
        this.showEmployments = true
        this.showEducations = false
      } else {
        this.showEmployments = true
        this.showEducations = true
      }
    },
  },
  mounted() {
    // if (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT) {
    //   this.getConfigInfo()
    //   if (!this.finished_tour) {
    //     this.$tours.ProfileTour.start()
    //   }
    // }
  },
  methods: {
    closeVerification() {
      this.$emit('reload-profile')
      this.openVerifyPopup = false
    },
    verifyContact(contactType) {
      if (contactType === 'email') {
        this.verifyType = 'email'
        this.openVerifyPopup = true
      } else {
        this.verifyType = 'phoneNumber'
        this.openVerifyPopup = true
      }
    },
    finishTour() {
      const { id } = this.$store.state.auth.ActiveUser
      const record = { tour_finished: true, tour_finished_on: new Date(), user_id: id }
      this.$http
        .put('/api/account-config', record)
        .then(response => {
          const { config } = response.data

          this.finished_tour = config.finished_tour
        })
        .catch(error => {})
    },
    gotoEdit(event) {
      if (this.$route.name === 'profile') {
        this.$router.push({ name: 'edit-profile' })
      } else {
        this.$router.push({ name: 'edit-corporate' })
      }
      // this.$router.push({name: 'edit-profile'})
      // this.$emit('clicked', false)
    },
    getConfigInfo() {
      this.$http
        .get('/api/account-config')
        .then(response => {
          const { config } = response.data
          this.finished_tour = config.tour_finished
        })
        .catch(error => {})
    },
    resendVerification() {
      const user_id = this.$store.state.auth.ActiveUser.id
      this.$http
        .get(`/api/auth/resend-verification/${user_id}`)
        .then(response => {
          const { message } = response.data
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Verifcation Update',
                icon: 'AlertCircleIcon',
                text: message,
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
        .catch(error => {})
    },
    getDefaultValue(item, list = false) {
      if (list) {
        if (item === null || item === undefined) {
          return 'Not Set'
        }
        if (item.length === 0) {
          return 'Not Set'
        }

        return item.join(', ')
      }
      if (
        item === null
        || item === undefined
        || item === ''
        || item.length <= 3
      ) {
        return 'Not Set'
      }
      return item
    },
    processDate(date_string) {
      try {
        if (date_string.toLowerCase() === 'current') {
          return format(new Date(), 'MMM yyyy')
        }
        return format(new Date(date_string), 'MMM yyyy')
      } catch (error) {
        return 'NA'
      }
    },

    processDiff(start_date, end_date) {
      try {
        if (end_date.toLowerCase() === 'current') {
          end_date = new Date()
        }
        return formatDistance(new Date(end_date), new Date(start_date))
      } catch (error) {
        return 'NA'
      }
    },

    onTab(tabName) {
      if (tabName === 'educations') {
        this.showEmployments = false
        this.showEducations = true
      } else {
        this.showEmployments = true
        this.showEducations = false
      }
    },

    changeOfTab(current_step) {
      this.gotoEdit()
    },
  },
}
</script>

<style lang="scss">

.break-word {
  -ms-word-break: break-all; /* Be VERY careful with this, breaks normal words wh_erever */
  word-break: break-all; /* Non standard for webkit */
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
