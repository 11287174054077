<!-- eslint-disable camelcase -->
<template>
  <div>
    <b-card
      v-if="accountInfo"
    >
      <div
        class="tabs-container"
      >
        <b-tabs
          id="profile"
          v-model="activeTab"
          lazy
          pills
          class="tab-action-btn-fill-container"
          justified
        >
          <b-tab
            title="Account"
            icon-pack="feather"
            icon="icon-user"
            @click="onTab"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="UserIcon"
                size="18"
              />
              <span>Account</span>
            </template>
            <div class="tab-text">
              <div id="account">
                <b-row class="fill-missing">
                  <div class="vx-col w-100">
                    <div
                      class="d-flex items-start"
                    >
                      <div
												class="ml-3"
                      >
												<b-col
													cols="12"
													class="d-flex flex-wrap align-items-center justify-content-center"
												>
													<img
														ref="refImageImg"
														class="cursor-pointer"
														fluid
														:src="accountInfo.avatar || $defaultProfileImage"
														width="200"
														height="200"
														data-placement="left"
														data-toggle="tooltip"
														title="Click here to upload an image"
														@click="$refs.uploadImageInput.click()"
													>
													<!-- <input
														ref="uploadImageInput"
														type="file"
														class="hidden"
														accept="image/*"
														@change="updateImage"
													> -->
												</b-col>
                      </div>

                      <!-- <b-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
                      <div
                        class="mx-2 w-100"
                      >
                        <div class="flex flex-wrap xl:w-1/2">
                          <div class="vx-col w-full">
                            <input
                              ref="uploadImageInput"
                              type="file"
                              class="hidden"
                              accept="image/*"
                              @change="updateImage"
                            >

                            <b-button
                              class="mt-1 mr-2 w-64 shadow-lg"
                              variant="primary"
                              @click="$refs.uploadImageInput.click()"
                            >Upload new image</b-button>
                          </div>

                          <!-- <div
                            style="width: 250px;height: 50px;"
                            class="mb-2"
                          >
                            <file-pond
                              ref="pond-user"
                              name="profile-pic-upload"
                              label-idle="Upload a new image."
                              accepted-file-types="image/jpeg, image/png"
                              :server="profileServer"
                              :files="profileFiles"
                              credits="false"
                              @init="handleProfileImagePondInit"
                            />
                          </div> -->
                        </div>

                        <div
                          v-if="false"
                          class=""
                        >
                          <b-alert
                            v-if="accountInfo.resume_file && normal == true"
                            class="mb-1 mt-1 p-1"
                            variant="warning"
                            icon-pack="feather"
                            icon="icon-info"
                            show
                          >You have an existing resume in the system. Click the
                            button above to update.
                          </b-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-row>

                <b-row
									class="mx-2"
                  cols="12"
                >
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Full Name">First Name</label>
                    <b-form-input
                      id="Full Name"
                      v-model="accountInfo.first_name"
                      debounce="5000"
                      :state="!checkFullName(accountInfo.first_name)[0]"
                      placeholder="First Name"
                    />
                    <b-form-invalid-feedback
                      tooltip
                    >
                      {{ checkFullName(accountInfo.first_name)[1] }}
                    </b-form-invalid-feedback>

                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Full Name">Last Name</label>
                    <b-form-input
                      id="Full Name"
                      v-model="accountInfo.last_name"
                      debounce="5000"
                      :state="!checkFullName(accountInfo.last_name)[0]"
                      placeholder="Last Name"
                    />
                    <b-form-invalid-feedback
                      tooltip
                    >
                      {{ checkFullName(accountInfo.first_name)[1] }}
                    </b-form-invalid-feedback>

                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Full Name">Username</label>
                    <b-form-input
                      id="Full Name"
                      v-model="accountInfo.username"
                      debounce="5000"
                      placeholder="Username"
                    />
                    <b-form-invalid-feedback
                      tooltip
                    >
                      {{ checkFullName(accountInfo.username)[1] }}
                    </b-form-invalid-feedback>

                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Email">Email Address</label>
                    <b-form-input
                      id="Email"
                      v-model="accountInfo.email"
                      type="email"
                      :state="accountInfo.email.length > 4 &&
                        accountInfo.email.includes('@') &&
                        accountInfo.email.includes('.')
                        ? true
                        : false"
                      placeholder="Email"
                    />
                    <b-form-invalid-feedback
                      class="ml-1"
                      tooltip
                    >
                      A valid email address is required
                    </b-form-invalid-feedback>

                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-1"
                  >
                    <label for="Contact Number">Mobile Number</label>
                    <b-form-input
                      id="Contact Number"
                      v-model="accountInfo.mobile_number"
                      :state="accountInfo.mobile_number.length === 8 ? true : false"
                      onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                      maxlength="8"
                      placeholder="Contact Number"
                    />
                    <b-form-invalid-feedback
                      class="ml-1"
                      tooltip
                    >
                      A valid mobile number is required
                    </b-form-invalid-feedback>
                  </b-col>

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1"
                  >

                    <label for="Gender">Gender</label>
                    <v-select
                      id="Gender"
                      v-model="accountInfo.gender"
                      :state="
                        accountInfo.gender !== 'Not Set' &&
                          accountInfo.gender !== ''
                          ? true
                          : false
                      "
                      danger-text="Please select your gender(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="allGenders"
                      :reduce="value => value.value"
                      label="value"
                    />
                  </b-col> -->

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label class="b-form-input--label">Highest Qualification</label>
                    <v-select
                      v-model="accountInfo.highest_qualification"
                      :danger="
                        accountInfo.highest_qualification !== 'Not Set' &&
                          accountInfo.highest_qualification !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your highest qualification(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="highestQualifications"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col> -->

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label class="b-form-input--label">Date Of Birth</label>
                    <flat-pickr
                      v-model="accountInfo.date_of_birth"
                      class="form-control"
                      :config="dobConfig"
                      placeholder="Date of birth"
                    />
                  </b-col> -->

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full md:w-1/2"
                  >
                    <label class="b-form-input--label">Employment Status</label>
                    <v-select
                      v-model="accountInfo.employment_status"
                      :danger="
                        accountInfo.employment_status !== 'Not Set' &&
                          accountInfo.employment_status !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your employment status(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="employmentStatus"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col> -->

                  <!-- <b-col
                    v-if="normal"
                    cols="12"
                    md="6"
                    class="mt-1 w-full"
                  >
                    <label class="b-form-input--label">Citizenship</label>
                    <v-select
                      v-model="accountInfo.citizenship"
                      :danger="
                        accountInfo.citizenship !== 'Not Set' &&
                          accountInfo.citizenship !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your citizenship(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="citizenship"
                      label="value"
                      :reduce="value => value.value"
                    />

                    <v-select
                      v-if="accountInfo.citizenship === 'Others'"
                      v-model="accountInfo.citizenship"
                      danger-text="Please select your citizenship(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full mt-1"
                      :options="allCitizenships"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col> -->

                  <!-- <b-col
                    cols="12"
                    md="6"
                    class="vx-col mt-1 w-full md:w-1/2"
                  >
                    <label for="Residential Postal">Residential Postal</label>
                    <b-form-input
                      id="Residential Postal"
                      v-model="accountInfo.postal_code"
                      class="w-full"
                      placeholder="Residential Postal"
                      :state="
                        !$isEmpty(accountInfo.postal_code)
                          && accountInfo.postal_code !== 'Not Set'
                          && accountInfo.postal_code !== ''
                          ? true
                          : false
                      "
                      danger-text="A valid residential postal is required"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                    />
                    <b-form-invalid-feedback
                      tooltip
                    >
                      A valid residential postal is required
                    </b-form-invalid-feedback>

                  </b-col> -->

                </b-row>

                <div
                  class="d-flex flex-row-reverse my-2"
                >
                  <b-button
                    class="mx-1 view-profile"
                    variant="primary"
                    @click="gotoView"
                  >View Profile</b-button>

                  <b-button
                    variant="warning"
                    class="mx-1 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab
            v-if="false"
            :class="normal === true ? '': 'invisible'"
            @click="onTab"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                id="view-employment"
                icon="LayersIcon"
                size="18"
              />
              <span>Employment</span>
            </template>
            <div class="tab-text">
              <div
                id="employment"
                class="w-full"
              >
                <div class="d-flex flex-row-reverse my-2">
                  <b-button
                    variant="warning"
                    class="mx-2 add-employment"
                    @click="createNewEmployment"
                  >Add new employment history</b-button>
                </div>
                <b-row
                  v-for="(item, index) in employmentInfo"
                  :key="index"
                  cols="10"
                >
                  <b-card
                    border-variant="dark"
                    bg-variant="transparent"
                    class="shadow-none mx-auto w-75 p-md-2"
                  >
                    <b-row class="m-0 p-0 mx-md-2 w-100">
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Position title</label>
                        <b-form-input
                          v-model="item.position"
                          class="w-full"
                          placeholder="Position title"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Company Name</label>
                        <b-form-input
                          v-model="item.company_name"
                          class="w-full"
                          placeholder="Company Name"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Start Date</label>
                        <div>
                          <flat-pickr
                            v-model="item.start_date"
                            class="form-control"
                            :config="configdateTimePicker"
                            placeholder="Start Date"
                          />
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">End Date</label>
                        <flat-pickr
                          v-model="item.end_date"
                          class="form-control"
                          :config="configdateTimePicker"
                          placeholder="End Date"
                        />
                      </b-col>

                      <b-col
                        cols="12"
                        class="w-100 md:w-1/2 mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Role Description</label>
                        <b-form-textarea
                          v-model="item.description"
                          placeholder=""
                          rows="5"
                        />
                      </b-col>
                    </b-row>

                    <div class="d-flex flex-row-reverse my-2">
                      <b-button
                        variant="danger"
                        class="mx-2"
                        @click="deleteEmployment(item.id, index)"
                      >Delete</b-button>
                    </div>
                  </b-card>
                </b-row>

                <div class="d-flex flex-row-reverse my-10">
                  <b-button
                    variant="primary"
                    class="mx-2 view-profile"
                    @click="gotoView"
                  >View Profile</b-button>

                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab
            v-if="false"
            :class="normal === true ? '': 'invisible'"
            title="Education"
            icon-pack="feather"
            icon="icon-book"
            @click="onTab"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                id="view-education"
                icon="BookIcon"
                size="18"
              />
              <span>Education</span>
            </template>
            <div class="tab-text">
              <div id="education">
                <div class="d-flex flex-row-reverse my-2">
                  <b-button
                    id="add-education"
                    variant="warning"
                    class="mx-2"
                    @click="createNewEducation"
                  >Add new education history</b-button>
                </div>
                <b-row
                  v-for="(item, index) in educationInfo"
                  :key="index"
                  class="vx-row mx-md-2"
                >
                  <b-card
                    border-variant="dark"
                    bg-variant="transparent"
                    class="shadow-none mx-auto w-75 p-md-2"
                  >
                    <b-row class="vx-row m-0 p-0 mx-md-2 w-100">
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">School</label>
                        <b-form-input
                          v-model="item.school_name"
                          class="w-full"
                          placeholder="School"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Field of Study</label>
                        <b-form-input
                          v-model="item.field_of_study"
                          class="w-full"
                          placeholder="Field of study"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">Start Date</label>
                        <div>
                          <flat-pickr
                            v-model="item.start_date"
                            class="form-control"
                            :config="configdateTimePicker"
                            placeholder="Start Date"
                          />
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                        class="mb-md-2 mt-1"
                      >
                        <label class="b-form-input--label">End Date</label>
                        <div>
                          <flat-pickr
                            v-model="item.end_date"
                            class="form-control"
                            :config="configdateTimePicker"
                            placeholder="End Date"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <div class="d-flex flex-row-reverse my-2">
                      <b-button
                        variant="danger"
                        class="mx-2"
                        @click="deleteEducation(item.id, index)"
                      >Delete</b-button>
                    </div>
                  </b-card>
                </b-row>

                <div class="d-flex flex-row-reverse my-2">
                  <b-button
                    variant="primary"
                    class="mx-2 view-profile"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab
            title="Security"
            icon-pack="feather"
            icon="icon-lock"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span>Security</span>
            </template>
            <div class="tab-text">
              <div id="security">
                <b-row class="my-1 mx-3">
                  <div
                    v-if="false"
                    class=""
                  >
                    <b-form-checkbox
                      v-model="accountInfo.two_factor_active"
                      class="mt-2 mx-1"
                    >Turn on two-factor authentication</b-form-checkbox>
                  </div>
                </b-row>
                <div class="container my-1 mx-2">
                  <hr>

                  <b-row class="mt-2 mx-1">
                    <h6 class="font-weight-bolder">
                      Change your password
                    </h6>
                  </b-row>

                  <b-row class="mt-1 mx-1">
                    <h6 class="text-danger ">
                      Your current password will expire
                      {{ accountInfo.days_left }}.
                    </h6>
                  </b-row>
                  <b-row
                    cols="12"
                    md="6"
                    class="mx-1"
                  >
                    <!-- password -->
                    <b-form-group
                      class="w-50"
                      label-for="old-password"
                      label="Old Password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Old Password"
                        vid="old-password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="old-password"
                            v-model="oldPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="old-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </b-row>
                  <b-row
                    cols="12"
                    md="5"
                    class="mx-1"
                  >
                    <b-form-group
                      label-for="settings-password"
                      label="Password"
                      class="w-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="password"
                        rules="required|password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="settings-password"
                            v-model="userPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="settings-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                  <b-row
                    cols="12"
                    md="5"
                    class="mx-1"
                  >
                    <!-- confirm password -->
                    <b-form-group
                      label-for="settings-confirm-password"
                      label="Confirm Password"
                      class="w-50"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        rules="required|confirmed:password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="settings-confirm-password"
                            v-model="confirmPassword"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false : null"
                            name="settings-confirm-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-row>
                </div>

                <b-row class="my-1 w-100 ml-2">
                  <b-button
                    :disabled="!validateForm"
                    class="ml-2"
                    variant="warning"
                    @click="saveProfile(false, true)"
                  >Change Password</b-button>
                </b-row>

                <div class="d-flex flex-row-reverse my-1">
                  <b-button
                    variant="primary"
                    class="mx-1 view-profile"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-1 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>

              </div>
            </div>
          </b-tab>

          <!-- <b-tab
            v-if="profileType"
            label="Connections"
            icon-pack="feather"
            icon="icon-link-2"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="Link2Icon"
                size="18"
              />
              <span>Connections</span>
            </template>
            <div class="tab-text">
              <div id="connection">
                <div class="vx-row mt-1 mx-2">
                  <b-button
                    v-if="has_logged_telegram"
                    icon-pack="feather"
                    icon="icon-send"
                    class="mr-3 mb-2"
                    variant="#54A8EB"
                    @click="telegramLogout"
                  >
                    Log out of telegram
                  </b-button>

                  <vue-telegram-login
                    v-else
                    mode="callback"
                    request-access="write"
                    :userpic="false"
                    :telegram-login="bot_username"
                    @callback="afterTelegram"
                  />
                </div>
                <hr>
                <div class="vx-row mt-2 mx-2">
                  <div
                    v-if="
                      this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
                    "
                    class=""
                  >
                    <b-form-checkbox
                      v-model="e2i_recommendation"
                      class="mt-2"
                    >Allow e2i to send you job recommendations</b-form-checkbox>
                  </div>
                </div>
                <hr>
                <div class="d-flex flex-row-reverse my-2">
                  <b-button
                    class="view-profile"
                    variant="primary"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveProfile(false)"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab> -->

          <b-tab
            v-if="false"
          >
            <template
              #title
              size="18"
            >
              <feather-icon
                icon="SmartphoneIcon"
                size="18"
              />
              <span>Point of Contact</span>
            </template>
            <div class="tab-text">
              <div id="poc">
                <div
                  v-if="
                    this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION ||
                      this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                  "
                  class="vx-row mx-1 mb-8"
                >
                  <b-card
                    no-shadow
                    card-border
                  >
                    <div class="header mb-2">
                      <h4>Company Settings</h4>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        1. Point of contact
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex flex-wrap"
                        >
                          <h6>
                            Full Name
                          </h6>
                          <b-form-input
                            v-model="poc.fullname"
                            class="w-full"
                            placeholder="Contact Full Name"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          md="6"
                          class="d-flex flex-wrap"
                        >
                          <h6>
                            Phone Number
                          </h6>
                          <!-- <b-form-input
                            type="number"
                            class="w-full"
                            placeholder="Contact Phone Number"
                            v-model="poc.phone_number"
                          /> -->
                          <b-input-group prepend="+65">
                            <b-form-input
                              v-model="poc.phone_number"
                              type="number"
                              placeholder="Contact Phone Number"
                              maxlength="8"
                              :formatter="$limitPhoneNumber"
                            />
                          </b-input-group>
                        </b-col>
                      </b-row>

                      <b-row class="mt-1">
                        <b-col class="d-flex flex-wrap">
                          <h6>
                            Email
                          </h6>
                          <b-form-input
                            v-model="poc.email_address"
                            type="email"
                            class="w-full"
                            placeholder="Contact Email"
                          />
                        </b-col>
                      </b-row>

                      <b-row class="mt-1">
                        <b-col class="d-flex flex-wrap">
                          <h6>
                            Agency License Number
                          </h6>
                          <b-form-input
                            v-model="poc.agency_number"
                            class="w-full"
                            placeholder="Agency Licence Number"
                          />
                        </b-col>
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        2. Which one of these functions do you do?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox
                            v-model="poc.recruitment"
                          >Placement</b-form-checkbox>
                        </b-col>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox
                            v-model="poc.outsourcing"
                          >Outsourcing</b-form-checkbox>
                        </b-col>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox v-model="poc.payroll">
                            Payroll
                          </b-form-checkbox>
                        </b-col>
                        <b-col class="vx-col md:w-1/4 w-full mb-2">
                          <b-form-checkbox
                            v-model="poc.other_func"
                          >Other functions</b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="poc.other_func"
                        class="vx-row mx-1 mt-1"
                      >
                        <b-form-input
                          v-model="poc.extra_functions"
                          class="w-full"
                          placeholder="Which other functions do you perform? If there are multiple functions, separate it with |"
                        />
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        3. Which type of jobs do you specialize in?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-checkbox
                            v-model="poc.parttime"
                          >Part-time</b-form-checkbox>
                        </b-col>
                        <b-col
                          cols="12"
                          md="6"
                        >
                          <b-form-checkbox
                            v-model="poc.fulltime"
                          >Full-time</b-form-checkbox>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        4. How much commission do you charge?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col
                          cols="12"
                          class="d-flex flex-wrap"
                        >
                          <h6>
                            Commission
                          </h6>
                          <b-form-input
                            v-model="poc.commission"
                            class="w-full"
                            placeholder="Commission For Example, 3% per annum"
                          />
                        </b-col>
                        <b-col cols="12">
                          <div class="vx-col md:w-1/3 w-full mt-2">
                            <b-form-checkbox
                              v-model="poc.offer_comp"
                            >We offer competitive rates for bulk
                              orders.</b-form-checkbox>
                          </div>
                        </b-col>
                        <b-col
                          v-if="poc.offer_comp"
                        >
                          <b-row class="mt-1">
                            <b-col>
                              <h6>
                                Number of Orders
                              </h6>
                              <b-form-input
                                v-model="poc.num_orders"
                                class="w-full"
                                placeholder="Above how many orders?"
                              />
                            </b-col>
                            <b-col>
                              <h6>
                                Bulk Commission
                              </h6>
                              <b-form-input
                                v-model="poc.bulk_commission"
                                class="w-full"
                                placeholder="Bulk commission For Example, 3% per annum"
                              />
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>

                    <div class="mt-2 mb-1 underline">
                      <h6 class="font-weight-bolder">
                        5. Which industries do you serve?
                      </h6>
                    </div>
                    <div class="mx-2">
                      <b-row>
                        <b-col>
                          <b-form-radio
                            v-model="poc.industries"
                            value="all"
                          >All industries</b-form-radio>
                        </b-col>
                        <b-col>
                          <b-form-radio
                            v-model="poc.industries"
                            value="specific"
                          >Specific industries</b-form-radio>
                        </b-col>

                        <b-col
                          cols="12"
                          class="mt-1"
                        >
                          <div
                            v-if="poc.industries == 'specific'"
                          >
                            <v-select
                              v-model="poc.selected_industries"
                              multiple
                              autocomplete
                              placeholder="Select the specific industries you serve"
                              class="w-full"
                              name="item-category"
                              :options="prefIndustry"
                              label="value"
                              :reduce="value => value.value"
                            />
                          </div>
                        </b-col>
                      </b-row>

                    </div>
                  </b-card>
                </div>

                <div class="d-flex flex-row-reverse my-10">
                  <b-button
                    class="mx-2 view-profile"
                    variant="primary"
                    @click="gotoView"
                  >View Profile</b-button>
                  <b-button
                    variant="warning"
                    class="mx-2 click-save"
                    @click="saveCompany()"
                  >Save Profile</b-button>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <v-tour
        v-if="!finished_tour"
        ref="tourguide"
        name="ProfileTour"
        :steps="steps"
        :callbacks="tourCallbacks"
        :options="{ highlight: false }"
      />
    </b-card>
  </div>
</template>

<script>
// import Datepicker from 'vuejs-datepicker'

// import { vueTelegramLogin } from 'vue-telegram-login'
// import flatPickr from 'vue-flatpickr-component'
// import 'flatpickr/dist/flatpickr.css'
// import 'flatpickr/dist/themes/airbnb.css'
import useJwt from '@/auth/jwt/useJwt'

import {
  cloneDeep, debounce, difference, orderBy,
} from 'lodash'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password,
} from '@validations'

// import { allIndustries, highestQualifications, allCitizenships } from '@/constants/datastore'

import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BFormCheckbox,
  BFormRow,
  BTabs, BTab,
  BFormTextarea,

  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
// import { singleUpload } from '@/components/awsS3'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VSelect from 'vue-select'

// import vueFilePond from 'vue-filepond'
// // Import FilePond styles
// import 'filepond/dist/filepond.min.css'

// // Import FilePond plugins
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Create component
// const FilePond = vueFilePond(
//   FilePondPluginFileValidateType,

// )

// const dict = {
//   custom: {
//     password: {
//       regex: () => 'Your password must be a minimum of 8 characters and must contain at least one uppercase letter, one number and one symbol.',
//     },
//   },
// }

export default {
  components: {
    // FilePond,
    ValidationProvider,
    ValidationObserver,
    VSelect,
    BFormTextarea,
    BTabs,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
    BFormRow,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BFormCheckbox,
    BFormInvalidFeedback,
    // Datepicker,
    // vueTelegramLogin,
    // flatPickr,

  },
  mixins: [togglePasswordVisibility],
  props: {
    accountInfo: { type: Object },
    companyInfo: { type: Object },
    employmentInfo: { type: Array },
    educationInfo: { type: Array },
    poc: { type: Object },
    profileType: { type: Boolean },
  },
  data() {
    return {
      formData: new FormData(),

      resumeFiles: [],
      resumeServer: {

      },
      companyServer: {

      },
      profileServer: {

      },
      companyProfileServer: {

      },
      companyProfileFiles: null,
      profileFiles: null,
      companyFiles: null,
      notify_resume_parser: false,
      tourCallbacks: {
        onSkip: this.finishTour,
        onFinish: this.finishTour,
        onPreviousStep: this.changeOfTab,
        onNextStep: this.changeOfTab,
      },
      finished_tour: true,
      // steps: [
      //   {
      //     target: '.fill-missing', // We're using document.querySelector() under the hood
      //     content: 'Please fill all fields that have been highlighted below.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.click-save', // We're using document.querySelector() under the hood
      //     content: 'Click here to save your account details.',
      //   },
      //   {
      //     target: '#upload-resume', // We're using document.querySelector() under the hood
      //     content:
      //       'Click here to upload your resume. We will help you to fill up your education and employment history using the information provided in the resume.',
      //   },
      //   {
      //     target: '#view-employment', // We're using document.querySelector() under the hood
      //     content:
      //       'We have extracted your employment history. Click here to view and edit your employment history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.add-employment', // We're using document.querySelector() under the hood
      //     content: 'Click here to add your employment history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.click-save', // We're using document.querySelector() under the hood
      //     content: 'Click here to save your employment history.',
      //   },
      //   {
      //     target: '#view-education', // We're using document.querySelector() under the hood
      //     content:
      //       'We have extracted your education history. Click here to view and edit your education history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '#add-education', // We're using document.querySelector() under the hood
      //     content: 'Click here to add your education history.',
      //     params: {
      //       placement: 'top',
      //     },
      //   },
      //   {
      //     target: '.click-save', // We're using document.querySelector() under the hood
      //     content: 'Click here to save your education details.',
      //   },
      //   {
      //     target: '.view-profile', // We're using document.querySelector() under the hood
      //     content: 'Click here to view a summary of your profile details.',
      //   },
      // ],
      activeTab: 0,
      userPassword: '',
      confirmPassword: '',
      oldPassword: '',
      latestUpdates: {
        employment: false,
        education: false,
        account: false,
        poc: false,
      },

      e2i_recommendation: false,
      pause_watch: {
        employment: false,
        education: false,
        account: false,
      },
      first_run: true,
      dobConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
      configdateTimePicker: {
        inline: false,
      },
      has_logged_telegram: false,
      normal: true,
      bot_username: '',
      allGenders: [
        {
          text: 'Male',
          value: 'Male',
        },
        {
          text: 'Female',
          value: 'Female',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],
      highestQualifications: [],
      employmentStatus: [
        {
          text: 'Unemployed for less than 6 months',
          value: 'Unemployed for less than 6 months',
        },
        {
          text: 'Unemployed for more than 6 months',
          value: 'Unemployed for more than 6 months',
        },
        {
          text: 'Employed',
          value: 'Employed',
        },
      ],

      citizenship: [
        {
          text: 'Singaporean',
          value: 'Singaporean',
        },
        {
          text: 'Singaporean PR',
          value: 'Singaporean PR',
        },
        {
          text: 'Others',
          value: 'Others',
        },
      ],

      all_availability: [
        {
          text: 'Days',
          value: 'Days',
        },
        {
          text: 'Weeks',
          value: 'Weeks',
        },
        {
          text: 'Months',
          value: 'Months',
        },
      ],

      prefWorkLocation: [
        {
          text: 'North',
          value: 'North',
        },
        {
          text: 'South',
          value: 'South',
        },
        {
          text: 'Central',
          value: 'Central',
        },
        {
          text: 'East',
          value: 'East',
        },
        {
          text: 'West',
          value: 'West',
        },
      ],

      prefIndustry: [],
      preferred_position_options: [],

      profilePopup: false,
      current_event: '',
      view_ownership_document_popup: false,
      view_resume_popup: false,
      document_type: 'pdf',
      ownership_document_source: '',
      file_source: '',
      required,
      password,
      email,
      confirmed,
      allCitizenships: [],
      isLoading: true,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    validateForm() {
      return (
        this.userPassword !== '' && this.confirmPassword !== ''
      )
    },
  },
  watch: {
    accountInfo: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (!this.first_run && !this.latestUpdates.account) {
          const outer_this = this
          this.latestUpdates.account = true

          debounce(() => {
            if (outer_this.$route.name !== 'edit-corporate') {
              outer_this.saveProfile()
            } else {
              outer_this.saveProfile(true)
            }
          }, 5000)()
        } else {
          this.first_run = false
        }
      },
    },

    activeTab(newVal) {
      if (newVal === 1) {
        this.changeOfTab(3)
      } else if (newVal === 2) {
        this.changeOfTab(6)
      }
    },
  },
  mounted() {
    // if (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT) {
    //   if (!this.finished_tour) {
    //     this.$tours.ProfileTour.start()
    //   }
    // }
  },

  created() {
    this.first_run = true
    const { token } = this.$store.state.auth.ActiveUser
    this.$http.defaults.headers.common.Authorization = `Token ${token}`

    // this.image_url = this.$store.state.userImg;
    const company = this.$store.state.auth.ActiveUser.company_name
    if (company === 'e2i') {
      this.bot_username = 'e2i_Bot'
    } else if (company === 'PersolKelly') {
      this.bot_username = 'PERSOLKELLY_BOT'
    } else if (company === 'IoTalents') {
      this.bot_username = 'IoTalents_Bot'
    } else if (company === 'SteadyLah Network') {
      this.bot_username = 'SteadyLah_Network_Bot'
    } else if (company === 'Julian Grey') {
      this.bot_username = 'julian'
    } else if (company === 'DYNAMIC HUMAN CAPITAL PTE. LTD.') {
      this.bot_username = 'DHC_DEMO_BOT'
    } else if (company === 'FASTCO PTE. LTD.') {
      this.bot_username = 'FastJobs_Bot'
    } else if (company === 'ELITEZ & ASSOCIATES PTE. LTD.') {
      this.bot_username = 'Elitez_Demo_Bot'
    } else if (company === 'ELITEZ PTE. LTD.') {
      this.bot_username = 'Elitez_and_Associates_Demo_Bot'
    } else if (company === 'ADECCO PERSONNEL PTE LTD') {
      this.bot_username = 'Adecco_Personnel_Bot'
    } else {
      this.bot_username = 'JobsBank_Bot'
    }

    const { account_type } = this.$store.state.auth.ActiveUser
    // if (account_type === this.$AccountTypes.APPLICANT) {
    //   this.normal = true
    // } else {
    //   this.normal = false
    // }

    // this.prefIndustry = orderBy(allIndustries, ['value'], ['asc'])
    // this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])
    // this.allGenders = orderBy(this.allGenders, ['value'], ['asc'])
    // this.citizenship = orderBy(this.citizenship, ['value'], ['asc'])
    // this.employmentStatus = orderBy(this.employmentStatus, ['value'], ['asc'])

    // this.all_availability = orderBy(this.all_availability, ['value'], ['asc'])
    // this.prefWorkLocation = orderBy(this.prefWorkLocation, ['value'], ['asc'])

    // this.allCitizenships = orderBy(allCitizenships, ['value'], ['asc'])

    // if (this.accountInfo.telegram_id !== 0) {
    //   this.has_logged_telegram = true
    // }
    this.getConfigInfo()
  },
  methods: {

    handleResumePondInit() {
      const { id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-resume']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-resume', `${id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },

    handleCompanyFilePondInit() {
      const { company_id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-company-document']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()
            singleUpload(file, 'ownership-documents', `${company_id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },
    handleCompanyProfileImagePondInit() {
      const { company_id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-company']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()
            singleUpload(file, 'company-profile-images', `${company_id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },
    handleProfileImagePondInit() {
      const { id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      const imgRef = this.$refs.profileImage
      this.$refs['pond-user']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-profile-images', `${id}.${extension}`, load, error, progress, abort, imgRef, outerThis)
          },
        },
      })

      // FilePond instance methods are available on `this.$refs.pond`
    },
    resumeClosed() {
      this.file_source = null
    },
    ownershipDocumentClosed() {
      this.ownership_document_source = null
    },
    getPresignedUploadUrl(extension, fd, reader, file) {
      this.$http
        .get(`/api/pre-signed-url/${extension}`)
        .then(response => {
          const record = response.data
          this.uploadImgS3(fd, reader, file, record)
        })
        .catch(error => {
          
        })
    },
    checkFullName(fullname) {
      let message = ''
      let result = false
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/

      if (!(fullname.length > 2)) {
        message = 'A valid fullname is required (only letters allowed)'
        result = true
      }

      if (format.test(fullname)) {
        message = 'A valid fullname is required (only letters allowed)'
        result = true
      }

      return [result, message]
    },

    viewUploadedResume() {
      // Math.random() is to prevent image being cache and display the previous file, when user upload or changed the resume file 
      const fileUrl = `${this.$bucketUrl}/${this.accountInfo.resume_file}?${Math.random(0, 30000000)}`
      if (this.accountInfo.resume_file && this.accountInfo.resume_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.file_source = `${fileUrl}?random=${new Date().getTime()}`
      } else {
        this.document_type = 'docs'
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
      }
      this.view_resume_popup = true
    },

    viewUploadedOwnershipDocument() {
      const fileUrl = `${this.$bucketUrl}/${this.companyInfo.ownership_document_file}`

      if (this.companyInfo.ownership_document_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.ownership_document_source = `${fileUrl}?random=${new Date().getTime()}`
      } else {
        this.document_type = 'docs'
        this.ownership_document_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
      }
      this.view_ownership_document_popup = true
    },
    manualResume() {
      this.notify_resume_parser = false
    },
    goToResume() {
      if (this.normal) {
        this.activeTab = 0
        this.notify_resume_parser = false
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Resume Upload',
              icon: 'AlertCircleIcon',
              text: 'Click on the `Upload your resume` or`Update your resume` button to upload your resume file. Our system will extract your education and employment information if added.',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    changeOfTab(current_step) {
      if (this.normal) {
        if (current_step === 3) {
          this.activeTab = 1
          setTimeout(() => { // wait for a bit for the button to appear
            if (this.$refs.tourguide) {
              this.$refs.tourguide.currentStep = 4
            }
          }, 300)

          if (this.$isEmpty(this.employmentInfo) && this.$isEmpty(this.accountInfo.resume_file)) {
            this.notify_resume_parser = true
          }
        } else if (current_step === 6) {
          this.activeTab = 2
          setTimeout(() => { // wait for a bit for the button to appear
            if (this.$refs.tourguide) {
              this.$refs.tourguide.currentStep = 7
            }
          }, 300)

          if (this.$isEmpty(this.educationInfo)) {
            this.notify_resume_parser = true
          }
        }
      }
    },
    onTab() {
      if (this.normal) {
        if (this.activeTab === 1) {
          if (this.$refs.tourguide) {
            this.$refs.tourguide.currentStep = 4
          }

          if (this.employmentInfo.length === 0) {
            this.notify_resume_parser = true
          }
        } else if (this.activeTab === 2) {
          if (this.$refs.tourguide) {
            this.$refs.tourguide.currentStep = 7
          }

          if (this.educationInfo.length === 0) {
            this.notify_resume_parser = true
          }
        } else if (this.activeTab === 0) {
          if (this.$refs.tourguide) {
            this.$refs.tourguide.currentStep = 2
          }
        }
      }
    },
    finishTour() {
      const { id } = this.$store.state.auth.ActiveUser
      const record = { tour_finished: true, tour_finished_on: new Date(), user_id: id }
      this.$http
        .put('/api/account-config', record)
        .then(response => {
          const { config } = response.data
          
          this.finished_tour = config.tour_finished
        })
        .catch(error => {})
    },

    sendCompletedProfile() {
      const all_data = { completed_profile: true }
      this.$http
        .put('/api/account-config', { data: all_data })
        .then(response => {
          const config = response.data

          if (!response.data.first_time) {
            this.profilePopup = config.completed_profile
          }

          this.$http
            .put('/api/account-config', { data: { first_time: true } })
            .then(response => {
              if (!response.data.first_time) {
                this.profilePopup = response.data.completed_profile
              }
            })
            .catch(error => {})
          this.finished_tour = config.tour_finished
          this.e2i_recommendation = config.allow_recommendation
        })
        .catch(error => {})
    },
    updateConfigInfo() {
      const all_data = { e2i_recommendation: this.e2i_recommendation }
      this.$http
        .put('/api/account-config', { data: all_data })
        .then(response => {
          const { config } = response.data

          this.finished_tour = config.tour_finished
          this.e2i_recommendation = config.allow_recommendation
        })
        .catch(error => {})
    },

    getConfigInfo() {
      this.$http
        .get('/api/account-config')
        .then(response => {
          const { config } = response.data

          if (config.first_time === false) {
            this.profilePopup = config.completed_profile
          }
          this.finished_tour = config.tour_finished
          this.e2i_recommendation = config.allow_recommendation
          // this.sendCompletedProfile();
        })
        .catch(error => {})
    },

    stayOnPage() {
      this.profilePopup = false
      this.sendCompletedProfile()
    },
    tourDone(currentStep) {},
    gotoEdit(event) {
      if (this.$route.name === 'profile') {
        this.$router.push({ name: 'edit-profile' })
      } else {
        this.$router.push({ name: 'edit-corporate' })
      }
      // this.$emit('clicked', true)
    },
    gotoView(event) {
      if (this.$route.name === 'edit-corporate') {
        this.$router.push('/corporate-profile')
      } else {
        this.$router.push({ name: 'profile' })
      }
    },

    saveProfile(company = false, password = false) {
			let proceed = true
			const { token } = this.$store.state.auth.ActiveUser
			this.$http.defaults.headers.common.Authorization = `Token ${token}`

			this.accountInfo.mobile_number = this.accountInfo.mobile_number.toString()
			if (this.accountInfo.mobile_number.toString().length !== 8 && this.$route.name === 'edit-profile') {
				this.$toastDanger('Invalid Mobile Number', 'The mobile number that you have filled is invalid.')
				proceed = false
			}

			const form_data = this.accountInfo

			form_data.password = null
			form_data.old_password = null
			if (password) {
				form_data.password = this.userPassword
				form_data.old_password = this.oldPassword
			}

      this.formData.append('form_data', JSON.stringify(form_data))

			if (proceed) {
				this.$http
					.put(
						'/api/users', 
						this.formData,
						{
							'Content-Type': 'multipart/form-data',
						},
					)
					.then(response => {
						const { output, actions, success } = response.data
						if (response.data.errors) {
							if (response.data.errors[0] === 'User not found') {
								localStorage.removeItem('userInfo')
								localStorage.removeItem('email')
								localStorage.removeItem('accessToken')
								this.$router.push('/auth/login')
							}
						} else if (response.data.password_error) {
							this.$toastDanger('Password Error', response.data.message)
						} else {
							if (success) {
								this.accountInfo.avatar = output.profile_image + `?${Math.random(100, 999)}`
								this.$toastSuccess('Profile Update', 'Your profile has been updated successfully!')
	
								this.userPassword = ''
								this.confirmPassword = ''
								this.oldPassword = ''
	
								if (actions.includes('changed_email')) {
									useJwt.setToken(output.token)
								}
								
								this.$store.commit('auth/UPDATE_USER_INFO', response.data.output)
								this.latestUpdates.account = true
							} else {
								this.$toastDanger('Operation Failed', response.data.message)
							}
						}

						this.isLoading = false
						// this.$emit("updateProfile", true);
					})
					.catch(() => {})
			}
    },
    updateImage(input) {
      const file = input.target.files[0]
      this.previewImage(file, 'refImageImg')
      this.formData.append('profile_image_file', file)
    },

    previewImage(file, imgRef) {
      const image = file
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = e =>{
        this.$refs[imgRef].src = e.target.result
      };
    },

    createNewEmployment() {
      this.employmentInfo.push({
        company_name: null,
        position: null,
        description: null,
        start_date: null,
        end_date: null,
        id: null,
      })
      window.scrollTo(
        0, document.body.scrollHeight || document.documentElement.scrollHeight,
      )
    },

    deleteEmployment(item_id, item_index) {
      if (item_id === 0) {
        this.employmentInfo.splice(item_index, 1)
      } else {
        this.updateEmployment('delete', item_id, item_index)
      }
    },

    createNewEducation() {
      this.educationInfo.push({
        school_name: null,
        field_of_study: null,
        start_date: null,
        end_date: null,
        id: null,
      })
      window.scrollTo(
        0,
        document.body.scrollHeight || document.documentElement.scrollHeight,
      )
    },

    deleteEducation(item_id, item_index) {
      if (item_id === 0) {
        this.educationInfo.splice(item_index, 1)
      } else {
        this.updateEducation('delete', item_id, item_index)
      }
    },

    getPOC() {
      this.$http
        .get('/api/poc')
        .then(response => {
          const poc = response.data.data
          if (poc) {
            this.poc = poc
          }
        })
        .catch(error => {})
    },

    getEmployment() {
      this.$http
        .get('/api/employment-data')
        .then(response => {
          this.employmentInfo = response.data.message

          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },
    getEducation() {
      this.$http
        .get('/api/education-data')
        .then(response => {
          this.educationInfo = response.data.message

          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },

    selectHandlers: (map, vm) => ({
      ...map,
      50: e => {
        e.preventDefault()
      },
    }),
  },
}
</script>

<style lang="scss">

  .flatpickr-monthDropdown-month{
    background-color: #fff!important;
  }
.v-tour {
  .v-step {
    background-color: rgba(var(--vs-dark), 1);
    border-radius: 0.5rem;
    padding: 1.5rem;
    filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.5));

    .v-step__arrow {
      border-color: rgba(var(--vs-dark), 1);
    }

    .b-button-border:not(.btn-tour-next):not(.btn-tour-finish) {
      border-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
</style>
